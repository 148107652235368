import React from "react";
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Header from '../../../components/Header/navmap/landingFleet';
import i18n from '../../../locale';
import sitemap from '../../../data/sitemap';
import Hero from './Hero';
import Advantages from './Advantages';
import Motivation from './Motivation';
import Customers from './Customers';
import CaseStudies from './CaseStudies';

export default ({ seo, location }) => (
  <Layout overlay    
    page={sitemap.landingFleet.why}
    header={Header}
    isLanding={'fleet'}
    url='_url:landing-fleet-why'
    homeLink={i18n('_url:landing-fleet')}>
    <SEO {...seo} location={location}/>
    <Hero/>
    <Advantages/>
    <Motivation/>
    <Customers/>
    <CaseStudies/>
  </Layout>
);