import i18n from '../../../locale';
import { landingFleet } from '../../../data/sitemap';

export default [{
    name: landingFleet._,
    text: i18n('Main'),
    url: i18n('_url:landing-fleet')
  },
  {
    name: landingFleet.features,
    text: i18n('Features'),
    url: i18n('_url:landing-fleet-features'),
    megaMenu: {
      columnCount: 1,
      menuWidth: 500,
      columns: [
        {
          title: i18n('Complete solution'),
          subTitle: i18n('Easy and simple to use'),
          width: 12,
          items: [
            {
              name:landingFleet.features,
              title: i18n('Different vehicle types'),
              subTitle: i18n('Connect your car, van or trailer'),
              url: `${i18n('_url:landing-fleet-features')}#${i18n('_hash:different-vehicle-types')}`,
              width: 12,
            },
            {
              name:landingFleet.features,
              title: i18n('Fleet dashboard'),
              subTitle: i18n('For the administrator'),
              url: `${i18n('_url:landing-fleet-features')}#${i18n('_hash:fleet-dashboard')}`,
              width: 12,
            },
            {
              name:landingFleet.features,
              title: i18n('App for drivers'),
              subTitle: i18n('For the employee on the road'),
              url: `${i18n('_url:landing-fleet-features')}#${i18n('_hash:driver-app')}`,
              width: 12,
            }
          ],
        }, 
      ]
    }
  },
  {
    name: landingFleet.why,
    text: i18n('Why Fleet'),
    url: i18n('_url:landing-fleet-why'),
    megaMenu: {
      columnCount: 2,
      menuWidth: 1100,
      columns: [
        {
          title: i18n('Case studies'),
          subTitle: i18n('Learn more about our services'),
          width: 4,
          items: [
            {
              name: landingFleet.why,
              title: i18n('Construction'),
              subTitle: i18n('Downtown ApS'),
              url: 'https://da.obiplus.com/blog/flaadestyringsloesning-for-medarbejdere',
              blank: true,
              width: 12,
            },
            {
              name: landingFleet.why,
              title: i18n('Education'),
              subTitle: i18n('Zealand Erhvervsakademi'),
              url: 'https://da.obiplus.com/blog/zealand-erhvervsakademi-case-studie',
              blank: true,
              width: 12,
            },
          ],
        },
        {
          title: i18n('Benefits'),
          subTitle: i18n('Exploring the benefits'),
          width: 8,
          items: [
            {
              name: landingFleet.why,
              title: i18n('Remote vehicle monitoring'),
              subTitle: i18n('Direct link to vehicles 24/7'),
              url: i18n('_url:landing-fleet-why'),
              width: 6,
            },
            {
              name: landingFleet.why,
              title: i18n('Reporting'),
              subTitle: i18n('Schedule relevant reports'),
              url: i18n('_url:landing-fleet-why'),
              width: 6,
            },
            {
              name: landingFleet.why,
              title: i18n('Preventive maintenace'),
              subTitle: i18n('Schedule and automate all maintenance'),
              url: i18n('_url:landing-fleet-why'),
              width: 6,
            },
            {
              name: landingFleet.why,
              title: i18n('Increased safety for employees'),
              subTitle: i18n('Identify issues remotely'),
              url: i18n('_url:landing-fleet-why'),
              width: 6,
            },
            {
              name: landingFleet.why,
              title: i18n('Driving and visiting history'),
              subTitle: i18n('View vehicle\'s driving history'),
              url: i18n('_url:landing-fleet-why'),
              width: 6,
            },
            {
              name: landingFleet.why,
              title: i18n('Driving logbook'),
              subTitle: i18n('Less administrative work'),
              url: i18n('_url:landing-fleet-why'),
              width: 6,
            },
          ],
        },
      ]
    }
  },
  {
    name: landingFleet.price,
    text: i18n('Price'),
    url: i18n('_url:landing-fleet-pricing')
  },
  {
  name: landingFleet.signup,
  text: i18n('Tilmeld dig på 2 min'),
  url: 'https://fleet.obiplus.net/signup',
  blank: true,
  callToAction: true 
}];